.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 1000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 247px;
}

.menu_item {
  display: grid;
  grid-template-columns: 37px auto;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  width: 247px;
  height: 46px;
  border-radius: 8px;
  font-family: Ubuntu;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.3px;
  text-align: left;
}

.menu_item svg {
  fill: #596987;
  margin-right: 10px;
  flex-shrink: 0;
}

.menu_item:hover {
  background-color: #f2f8f8;
  color: #0a8081;
}

.menu_item:hover svg {
  fill: #0a8081;
}

.deleteItem {
  color: red;
  margin-left: 4px;
  grid-template-columns: 34px auto;
}
.menu_invitation {
  margin-left: 2px;
  grid-template-columns: 35px auto;
}

.deleteItem:hover {
  background-color: #ffe0e0;
  color: red;
}
