.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #f2f8f8;
  height: 56px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.title h6 {
  flex: 1;
  text-align: center;
  margin: 0;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #152837;
  cursor: default;
}

.title .closeButton {
  position: absolute;
  right: 16px;
}

.closeButton {
  background-color: #f2f8f8;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.closeButton svg {
  width: 18px;
  height: 18px;
  fill: #596987;
}

.closeButton:hover svg {
  fill: #0a8081;
}

.modalContent {
  background: white;
  border-radius: 8px;
  width: 545px;
  max-width: 90%;
  box-sizing: border-box;
}

.tagNameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  p {
    font-family: Ubuntu;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: #152837;
  }
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  padding: 0 16px 16px 16px;
  gap: 8px;
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 23px 14px 28px;
    gap: 12px;
    height: 52px;
    background: #0a8081;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-family: Ubuntu;
    &:disabled {
      background-color: #f2f8f8;
      color: #c6e1fd;
      cursor: default;
      &:hover {
        background-color: #f2f8f8;
      }
    }
    &:hover {
      background-color: #3b999a;
    }
  }
}
