.company {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  max-height: 87.8vh;
}

.wrapperCompany,
.companyListLimited,
.wrapperCompanyExtra {
  margin-top: 24px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.wrapperCompanyExtra {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.companyList {
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 16px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.32px;
  text-align: left;
}

.companyListLimited {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.noResults {
  padding: 50px 0;
  font-family: 'Ubuntu';
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #596987;
}

.companyItem {
  border-bottom: 1px solid #ddd;
  display: grid;
  grid-template-columns: 38px 0.6fr 12ch 0.26fr 0.25fr 0.2fr 48px;
  align-items: center;
  height: 75px;
  justify-content: space-between;
}

.lastCompanyItem {
  border-bottom: none;
}

.inotify {
  display: flex;
  align-items: center;
  gap: 14px;
}

.wrapAvatar {
  width: 50px;
  height: 50px;
  background-color: #e2ecf4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #0a8081;
  }
}

.avatar {
  flex-shrink: 0;
}

.clientMenu {
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  height: 48px;
  width: 48px;
  cursor: pointer;
}

.clientMenu svg {
  fill: #0a8081;
}

.clientMenu:hover {
  background-color: #0a8081;
}

.clientMenu:hover svg {
  fill: #ffffff;
}

.consultation {
  background-color: #f9d3ca;
  color: #ff710a;
  width: 80px;
  height: 28px;
}

.eventTranslation {
  background-color: #f9f4d9;
  color: #ff9f0a;
  width: 175px;
  height: 28px;
}

.subscription {
  background-color: #c6e1fd;
  color: #007aff;
  width: 75px;
  height: 28px;
}

.extended {
  background-color: #c7f0dc;
  color: #34c759;
  width: 152px;
  height: 28px;
}

.basic {
  background-color: #f8fafc;
  color: #596987;
  width: 90px;
  height: 28px;
}

.consultation,
.eventTranslation,
.subscription,
.extended,
.basic {
  border-radius: 4px;
  padding: 6px 8px 6px 8px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  letter-spacing: 0.02em;
  text-align: left;
  box-sizing: border-box;
}
.avatar {
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
}
