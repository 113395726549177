.inotify {
  display: flex;
  align-items: center;
  gap: 14px;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex-shrink: 0;
}

.clientMenu {
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  height: 48px;
  width: 48px;
  cursor: pointer;
  svg {
    fill: #0a8081;
  }
  &:hover {
    background-color: #0a8081;
    svg {
      fill: #ffffff;
    }
  }
  &:active {
    background-color: #3b999a;
    svg {
      fill: #ffffff;
    }
  }
}

.clientItem {
  border-bottom: 1px solid #ddd;
  display: grid;
  grid-template-columns: 38px 0.3fr 0.25fr 0.2fr 0.2fr 0.16fr 48px;
  align-items: center;
  height: 75px;
}
.lastClientItem {
  border-bottom: none;
}

.consultation {
  background-color: #f9d3ca;
  color: #ff710a;
  width: 102px;
  height: 28px;
}
.eventTranslation {
  background-color: #f9f4d9;
  color: #ff9f0a;
  width: 129px;
  height: 28px;
}
.subscription {
  background-color: #c6e1fd;
  color: #007aff;
  width: 75px;
  height: 28px;
}
.extended {
  background-color: #c7f0dc;
  color: #34c759;
  width: 99px;
  height: 28px;
}
.basic {
  background-color: #f8fafc;
  color: #596987;
  width: 71px;
  height: 28px;
}

.nonAvatar {
  width: 48px;
  height: 48px;
  background: #e2ecf4;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}
