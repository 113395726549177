.imageUploadContainer {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: #e2ecf4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.imageLabel {
  display: block;
}

.imageInput {
  display: none;
}

.uploadIcon {
  width: 18px;
  height: 18px;
  object-fit: cover;
}
