.title_new_pass {
  font-family: Ubuntu;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin: 0;
  padding-bottom: 8px;
}
.text_offer {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #596987;
  margin: 0;
  padding-bottom: 24px;
}
.text_rules {
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #596987;
  padding: 0 0 12px 8px;
  margin: 0;
}
.new_pass_container {
  .new_pass_form {
    gap: 12px;
  }
}
