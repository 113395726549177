.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #fff;
  border-radius: 8px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.infoTitle {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
}

.wrapInputs {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.submitWrap {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
}

.form {
  padding: 0 24px;
}
