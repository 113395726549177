/* SuccessModal.module.css */
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.42);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 4px;
  padding: 20px;
  max-width: 500px;
  width: 328px;
  height: 210px;
  padding: 24px;
  gap: 24px;
  border-radius: 12px;
}

.modal_content {
  text-align: center;
}

.modal_content button {
  width: 280px;
  height: 52px;
  padding: 14px 23px 14px 28px;
  gap: 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  background-color: #3b999a;
  color: #ffffff;
}

.modal_content button:hover {
  background-color: #0a8081;
}

.modal_title {
  margin-bottom: 4px;
}

.modal_text {
  margin-top: 0;
  margin-bottom: 24px;
}
