.submitButton {
  box-sizing: border-box;
  width: 212px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 0 14px 0;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  border: none;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  background: #0a8081;
  color: #ffffff;
}

.submitButton:hover {
  background-color: #3b999a;
}

.submitButton:disabled {
  background: #f2f8f8;
  cursor: default;
}
