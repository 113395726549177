.imageUploadContainer {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background: #e2ecf4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 24px 24px;
  cursor: pointer;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadIcon {
  width: 18px;
  height: 18px;
  object-fit: cover;
}

.uploadButton {
  margin-top: 10px;
  cursor: pointer;
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}

.imageInput {
  display: none;
}
