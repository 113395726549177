.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  width: 328px;
  height: 256px;
  background: #ffffff;
  border-radius: 12px;
  h6 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.32px;
    color: #152837;
  }
}

.img {
  margin-top: 12px;
}

.closeButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 23px 14px 28px;
  gap: 12px;
  width: 280px;
  height: 52px;
  background: #0a8081;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  color: #ffffff;
}

.closeButton:hover {
  background-color: 3B999A;
}
