.inputsContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 8px;
  gap: 2px;
}

.inputContainer:first-of-type input {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.inputContainer:last-of-type input {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.inputContainer {
  position: relative;
}

.inputContainer input {
  width: 164px;
  height: 50px;
  background-color: #f2f8f8;
  border: 1px solid #f2f8f8;
  border-radius: 0px;
  padding-left: 14px;
  padding-top: 15px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
}

.inputContainer input::placeholder {
  color: #b8c0cd;
}

.inputContainer input:hover {
  border: 1px solid #c7f0dc;
}

.inputContainer input:focus {
  outline: none;
  border: 1px solid #0a8081;
  background-color: #ffffff;
}

.inputContainer label {
  position: absolute;
  top: 8px;
  left: 15px;
  text-align: center;
  line-height: 20px;
  pointer-events: none;
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
}

.calendarIcon {
  position: absolute;
  bottom: 8px;
  right: 12px;
  svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
    fill: #596987;
    &:hover {
      fill: #0a8081;
    }
  }
}
