.searchWrap {
  display: flex;
  align-items: center;
  position: relative;
  margin: 24px;
  width: 388px;
  border: 1px solid #f2f8f8;
  border-radius: 6px;
  background-color: #f2f8f8;
  transition:
    border-color 0.3s,
    background-color 0.3s;
}

.searchWrap:focus-within {
  background-color: #ffffff;
  border-color: #0a8080;
}

.searchInput {
  padding: 8px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 24px 14px 48px;
  isolation: isolate;
  width: 388px;
  height: 52px;
  border: none;
  background: none;
  outline: none;
  box-sizing: border-box;
}

.searchIcon {
  position: absolute;
  top: 14px;
  left: 14px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.clearIcon {
  position: absolute;
  right: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.clearIcon svg {
  fill: #596987;
}

.clearIcon svg:hover {
  fill: #0a8080;
}

.searchWrap svg {
  fill: #596987;
}
