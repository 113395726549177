/* Snackbar.module.css */
.snackbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 18px;
  border-radius: 6px;
  z-index: 1000;
  width: 344px;
  height: 48px;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.03em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snackbarError {
  background-color: #f0c7c4;
  color: #eb5f5f;
}

.snackbarSuccess {
  background-color: #d4eebf;
  color: #797f71;
}
