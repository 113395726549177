.titlePreference {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f8f8;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 16px 0;
}

.titlePreference h5 {
  flex-grow: 1;
  text-align: center;
  margin: 0;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #152837;
}

.titlePreference button {
  background-color: #f2f8f8;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 20px;
}

.titlePreference button svg {
  fill: #152837;
}

.titlePreference button:hover svg {
  fill: #0a8081;
}
