.newTagInput {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  isolation: isolate;
  height: 52px;
  background-color: #f2f8f8;
  box-sizing: border-box;
  transition:
    border-color 0.3s,
    background-color 0.3s;
  padding: 14px;
}

.newTagInput:hover {
  border-color: #c7f0dc;
}

.newTagInput:focus {
  background-color: #ffffff;
  border-color: #0a8081;
  outline: none;
}
