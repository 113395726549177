.tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 637px;
  height: 56px;
  border-radius: 8px;
  padding: 15px 0 15px 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.tag:hover {
  background-color: #f2f8f8;
  color: #0a8081;
}

.selected {
  color: #0a8081;
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  svg {
    fill: #596987;
  }
}

.svgIcon {
  margin-left: auto;
  margin-right: 16px;
}
