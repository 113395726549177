.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  width: 254px;
  box-sizing: border-box;
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
}

.modalContent label {
  display: block;
}

.modalContent input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.saveButton,
.saveDisabled {
  height: 52px;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.saveButton {
  background-color: #0a8081;
  color: #ffffff;
  &:hover {
    background-color: #086b6c;
  }
}
.saveDisabled {
  background-color: #f2f8f8;
  color: #c6e1fd;
}

.timeChange {
  display: flex;
  justify-content: space-between;
}
