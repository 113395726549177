.chip {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: fit-content;
}

.reklama {
  background-color: #fdf5d4;
  color: #f6b73d;
}

.akcija {
  background-color: #fee7e1;
  color: #f08052;
}

.pryvitannia {
  background-color: #f6edff;
  color: #b77ce8;
}
