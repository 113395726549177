.adminItem {
  display: grid;
  grid-template-columns: 46px 1fr 1fr 64px;
  padding: 14px 0;
  margin: 0 16px;
  align-items: center;
  border-bottom: 1px solid #b8c0cd;
  box-sizing: border-box;
}

.adminItem:last-child {
  border-bottom: none;
}

.numberText {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  color: #b8c0cd;
}

.firsInfo {
  display: flex;
  align-items: center;
}

.withAvatar,
.wrapNoAvatar {
  width: 48px;
  height: 48px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.wrapNoAvatar {
  background: #e2ecf4;
  svg {
    fill: #0a8081;
  }
}

.adminName {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  color: #152837;
}

.adminEmail {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  color: #152837;
}

.deleteButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 12px;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  svg {
    fill: #0a8081;
  }
}

.deleteButton:hover {
  background-color: #0a8081;
  svg {
    fill: #ffffff;
  }
}

.deleteButton:active {
  background-color: #3b999a;
}
