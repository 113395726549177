.calendarContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.calendarGrid {
  display: grid;
  grid-template-columns: repeat(7, 58px);
  gap: 0;
}

.calendarCell {
  cursor: pointer;
  padding: 0;
  text-align: center;
  border: none;
  user-select: none;
  height: 58px;
  width: 58px;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendarCell.empty,
.calendarCell.leadingEmpty,
.calendarCell.trailingEmpty {
  cursor: default;
}
.leadingEmpty,
.trailingEmpty {
  color: #b8c0cd;
}

.calendarCell.leadingEmpty,
.calendarCell.trailingEmpty {
  pointer-events: none; /* Disable clicking */
}

.calendarCell.empty:hover {
  background-color: #0a8081;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

.calendarCell:not(.empty):not(.leadingEmpty):not(.trailingEmpty):hover:not(
    .selected
  ):not(.inRange) {
  background-color: #0a8081;
  color: #fff;
  border-radius: 8px;
}

.calendarCell.inRange:hover {
  background-color: #0a8081;
  color: #fff;
  border-radius: 0;
}

.calendarCell.selected {
  background-color: #0a8081;
  color: #fff;
  border-radius: 8px;
}

.calendarCell.selected.start {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.calendarCell.selected.end {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.calendarCell.selected.singleSelected {
  border-radius: 8px;
}

.calendarCell.inRange {
  background-color: #b2d8d8;
  color: #000;
  border-radius: 0;
}
