.wrapInput,
.wrapInputError {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.adminInput,
.adminInputError {
  width: 328px;
  height: 70px;
  box-sizing: border-box;
  background-color: #f2f8f8;
  border-radius: 8px;
  padding-left: 14px;
  padding-top: 15px;
  font-family: Ubuntu, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
}

.adminInput {
  border: 1px solid #f2f8f8;
}

.adminInput::placeholder {
  color: #b8c0cd;
}

.adminInput:hover {
  border: 1px solid #c7f0dc;
}

.adminInput:focus,
.adminInputError:focus {
  outline: none;
  border: 1px solid #0a8081;
  background-color: #ffffff;
}

.adminInputError:focus {
  outline: none;
  border: 1px solid #ff3b30;
}

.wrapInput .label {
  position: absolute;
  top: 8px;
  left: 15px;
  text-align: center;
  line-height: 20px;
  pointer-events: none;
  font-family: Ubuntu, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #596987;
}

.adminInputError {
  border-color: red !important;
}

.error {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 119%;
  display: flex;
  align-items: center;
  color: #ff3b30;
  position: absolute;
  bottom: -30px;
  left: 5px;
}

.adminInput {
  border-radius: 4px;
}
