.timePicker {
  display: flex;
  flex-direction: column;
  width: 105px;
  background-color: #f2f8f8;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border: 1px solid #f2f8f8;
}

.open {
  background-color: white;
  border: 1px solid #0a8081;
}

.open .timeSelect {
  background-color: white;
}

.label {
  padding: 14px 14px 5px 14px;
  font-size: 14px;
  color: #333;
  text-transform: uppercase;
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
}
.openLabel {
  color: #086b6c;
}

.selectWrapper {
  position: relative;
  width: 100%;
}

.timeSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f8f8;
  border: 1px solid #ccc;
  padding: 0 0 14px 14px;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  border: none;
}

.arrowWrapper {
  display: flex;
  align-items: center;
  padding-right: 14px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 12px;
  align-items: center;
  padding: 8px;
  display: grid;
  justify-items: center;
  box-sizing: border-box;
}

.options::-webkit-scrollbar {
  width: 8px;
}

.options::-webkit-scrollbar-thumb {
  background-color: #0a8081;
  border-radius: 4px;
  height: 24px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.option {
  padding: 8px;
  font-size: 16px;
  width: 75px;
  height: 55px;
  text-align: center;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 8px;
}

.option:hover {
  background-color: #e0f7fa;
  color: #0a8081;
}

.option:active {
  background-color: #b2ebf2;
  color: #00796b;
}
