.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 105px;
  isolation: isolate;
  background: #f2f8f8;
  border-radius: 12px 12px 0px 0px;
  position: relative;
  h5 {
    margin: 0;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: #152837;
    flex: 1;
    text-align: center;
  }
}

.closeButton {
  position: absolute;
  right: 20px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  svg {
    width: 14px;
    height: 14px;
    fill: #596987;
  }
  &:hover {
    svg {
      fill: #0a8081;
    }
  }
}

.avatarContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 92px;
  height: 92px;
  background: #f2f8f8;
  border-radius: 1000px;
  margin: 0 24px;
  img {
    width: 92px;
    height: 92px;
  }
}

.avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px;
  h5 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    color: #152837;
    margin: 0;
  }
}

.warperInformation {
  box-sizing: border-box;
  width: 328px;
  height: 69px;
  background-color: #f2f8f8;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  padding: 14px;
  gap: 12px;
  isolation: isolate;
  border-radius: 6px;
  h6 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #596987;
  }
  p {
    margin: 0;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #152837;
  }
}

.wrap {
  display: flex;
  gap: 12px;
}

.nonAvatar {
  position: absolute;
  top: 34px;
  left: 34px;
}
