.inputContainer {
  position: relative;
}

.inputContainer input {
  width: 308px;
  height: 50px;
  background-color: #f2f8f8;
  border: 1px solid #f2f8f8;
  border-radius: 8px;
  padding-left: 14px;
  padding-top: 15px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
}

.inputContainer input::placeholder {
  color: #b8c0cd;
}

.inputContainer input:hover {
  border: 1px solid #c7f0dc;
}

.inputContainer input:focus {
  outline: none;
  border: 1px solid #0a8081;
  background-color: #ffffff;
}

.inputContainer label {
  position: absolute;
  top: 8px;
  left: 15px;
  text-align: center;
  line-height: 20px;
  pointer-events: none;
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
}

.error {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  color: #d9534f;
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  padding: 2px 14px;
  box-sizing: border-box;
}
