.cancelTranslationItem {
  border-bottom: 1px solid #ddd;
  display: grid;
  grid-template-columns: 38px 1fr 0.5fr 0.5fr 1fr;
  min-height: 76px;
  align-items: center;
  justify-content: space-between;
}

.lastCancelTranslationItem {
  border-bottom: none;
}

.comment {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 370px;
  word-wrap: break-word;
  color: #596987;
}
.usernameWrapper {
  display: flex;
  gap: 16px;
  margin: 0;
  padding: auto;
  justify-content: flex-start;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex-shrink: 0;
}

.avatarPlaceholder {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0a8081;
}
