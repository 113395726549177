.panel_wrap {
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container_button {
  display: flex;
  gap: 8px;
  button {
    box-sizing: border-box;
    padding: 14px 30px;
    gap: 12px;
    border-radius: 6px;
    border: none;
    background-color: #f2f8f8;
    color: #0a8081;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: left;
    cursor: pointer;
    &:hover {
      background-color: #0a8081;
      color: #ffffff;
      svg {
        fill: #ffffff;
      }
    }
    &:active {
      background-color: #3b999a;
    }
    svg {
      padding-right: 30px;
      fill: #0a8081;
    }
  }
}
