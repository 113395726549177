.wrapPush {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  position: relative;
  margin-bottom: 20px;
}

.notificationsList {
  background-color: #ffffff;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: scroll;

}

.pushTitles {
  display: grid;
  grid-template-columns: 46px 0.5fr 1fr;
  padding: 0 16px;
  border-bottom: 1px solid #e2ecf4;
}

.borderBottomRadius {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.typpePush {
  display: grid;
  grid-template-columns: 70px 105px 100px 115px 130px;
  justify-items: center;
  height: 48px;
  width: 100%;
}

.activeFilter {
  cursor: pointer;
  color: #0a8081;
  border-bottom: 2px solid #0a8081;
  width: 100%;
  text-align: center;
}

.nonActiveFilter {
  cursor: pointer;
  color: #596987;
  width: 100%;
}

.activeFilter,
.nonActiveFilter {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controlHead {
  display: grid;
  grid-template-columns: 38px 1fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 48px;
  padding: 12px 16px;
}

.controlHead h6 {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #596987;
}

.controlHead {
  background-color: #f2f8f8;
}

.emphtyItems {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #596987;
  padding: 45px 0;
}

.sortArrowButton,
.sortButton {
  border: none;
  background-color: #f2f8f8;
  cursor: pointer;
}

.sortArrowButton svg,
.sortButton svg {
  width: 14px;
  height: 16px;
  transition: transform 0.3s ease;
}

.sortBtnWrap h6 {
  margin: 0;
  padding-bottom: 4px;
}

.sortBtnWrap {
  display: flex;
  align-items: center;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.notificationItem {
  display: grid;
  grid-template-columns: 38px 1fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 48px;
  align-items: center;
  height: 98px;
  padding: 0 16px;
}

.notificationItem:hover {
  background-color: #f2f8f8;
}

.messageCol {
  width: calc(100% - 24px);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.nameCol {
  width: calc(100% - 24px);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.notificationMenu {
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  height: 48px;
  width: 48px;
  cursor: pointer;
}

.notificationMenu svg {
  fill: #0a8081;
}

.notificationMenu:hover {
  background-color: #0a8081;
}

.notificationMenu:hover svg {
  fill: #ffffff;
}
