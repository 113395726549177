.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f8f8;
  padding: 16px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 24px;
}

.modalHeader h6 {
  margin: 0 auto;
  text-align: center;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
