.inputContainer {
  position: relative;
}

.inputContainer input {
  width: 388px;
  height: 50px;
  background-color: #f2f8f8;
  border: 1px solid #f2f8f8;
  border-radius: 8px;
  padding-left: 14px;
  padding-right: 40px;
  padding-top: 15px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
}

.inputContainer input::placeholder {
  color: #b8c0cd;
}

.inputContainer input:hover {
  border: 1px solid #c7f0dc;
}

.inputContainer input:focus {
  outline: none;
  border: 1px solid #0a8081;
  background-color: #ffffff;
}

.inputContainer label {
  position: absolute;
  top: 8px;
  left: 15px;
  text-align: center;
  line-height: 20px;
  pointer-events: none;
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
}

.crossIcon {
  position: absolute;
  top: 32px;
  right: 15px;
  svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
    fill: #596987;
    &:hover {
      fill: #0a8081;
    }
  }
}
