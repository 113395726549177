.successModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 24px;
  position: absolute;
  width: 328px;
  height: 237px;
  left: calc(50% - 328px / 2);
  top: calc(50% - 237px / 2 + 0.5px);
  background: #ffffff;
  border-radius: 12px;
}

.title {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.32px;
  color: #152837;
}

.infoEmail {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #596987;
  margin: 0;
}

.successButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 23px 14px 28px;
  gap: 12px;
  width: 280px;
  height: 52px;
  background-color: #0a8081;
  border-radius: 6px;
  border: none;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  color: #ffffff;
  cursor: pointer;
}

.successButton:hover {
  background-color: #3b999a;
}

.successImg {
  margin-top: 16px;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
