.searchWrap {
  position: relative;
  width: 100%;
  padding-bottom: 16px;
  box-sizing: border-box;
}

.searchWrap .leadingIcon {
  fill: #596987;
  position: absolute;
  top: 17px;
  left: 17px;
  z-index: 1;
  transition: fill 0.3s;
}

.searchWrap .leadingIcon.focused {
  fill: #0a8081;
}

.searchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  isolation: isolate;
  width: 100%;
  height: 52px;
  background-color: #f2f8f8;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #f2f8f8;
  padding: 14px 14px 14px 52px;
  transition:
    border-color 0.3s,
    background-color 0.3s;
}

.searchInput:hover {
  border-color: #c7f0dc;
}

.searchInput:focus {
  background-color: #ffffff;
  border-color: #0a8081;
  outline: none;
}
