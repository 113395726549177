.passwordField input {
  background: #f2f8f8;
  border: none;
  outline: none;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #152837;
  cursor: pointer;
}

.passwordField label {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #596987;
  cursor: pointer;
}

.passwordField {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 14px;
  gap: 4px;
  isolation: isolate;
  height: 69px;
  background: #f2f8f8;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  box-sizing: border-box;
  border: 1px solid #f2f8f8;
  cursor: pointer;
  position: relative;
}

.inputPassWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.passwordField button {
  border: none;
  position: absolute;
  top: 38px;
  right: 14px;
  background-color: #f2f8f8;
  cursor: pointer;
}

.passwordField button svg {
  fill: #152837;
}

.passwordField button:hover svg {
  fill: #0a8081;
}

.passwordField.active {
  background-color: #ffffff;
  border: 1px solid #0a8081;
}

.passwordField.active input,
.passwordField.active button {
  background-color: #ffffff;
}

.inputPassWrap p {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #596987;
  margin: 0;
}

.titelPassWrap h6 {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  color: #152837;
  margin-bottom: 8px;
}

.titelPassWrap p {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  text-align: center;
  color: #596987;
  margin: 0;
}

.titelPassWrap {
  padding-bottom: 24px;
}
