.customerList,
.customerListLimited {
  margin-top: 24px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}
.customerListLimited {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.clientItems {
  overflow: hidden;
  max-height: calc(4.22rem * 10);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 16px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.32px;
  text-align: left;
}

.noResults {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.32px;
  text-align: center;
}

.clientItems::-webkit-scrollbar {
  display: none;
}
.clientInfo {
  display: flex;
  gap: 20px;
}

.loader {
  text-align: center;
  padding: 20px;
}

.consultation {
  background-color: #f9d3ca;
  color: #ff710a;
  width: 102px;
  height: 28px;
}

.eventTranslation {
  background-color: #f9f4d9;
  color: #ff9f0a;
  width: 129px;
  height: 28px;
}

.subscription {
  background-color: #c6e1fd;
  color: #007aff;
  width: 75px;
  height: 28px;
}

.extended {
  background-color: #c7f0dc;
  color: #34c759;
  width: 151px;
  height: 28px;
}

.basic {
  background-color: #f8fafc;
  color: #596987;
  width: 90px;
  height: 28px;
}

.consultation,
.eventTranslation,
.subscription,
.extended,
.basic {
  border-radius: 4px;
  padding: 6px 8px 6px 8px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  letter-spacing: 0.02em;
  text-align: left;
  box-sizing: border-box;
}

.noClients {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #596987;
  padding: 35px 0;
}
