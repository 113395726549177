.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: Inter;
  font-size: 17px;
  font-weight: 590;
  line-height: 22px;
  text-align: left;
  text-transform: capitalize;
  button {
    border: none;
    background-color: #f2f8f8;
    width: 52px;
    height: 52px;
    padding: 14px;
    gap: 12px;
    border-radius: 8px;
    cursor: pointer;
    svg {
      fill: #0a8081;
    }
    &:hover {
      background-color: #0a8081;
      svg {
        fill: #ffffff;
      }
      &:active {
        background-color: #3b999a;
      }
    }
  }
}

.arrowLeft {
  margin-left: 24px;
}
.arrowRight {
  margin-right: 24px;
}
