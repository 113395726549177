.modalContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 15px;
  display: grid;
  justify-items: center;
  align-content: center;
}

.bottomButton,
.topButton,
.bottomButtonInactive {
  border: none;
  cursor: pointer;
  background-color: #ffffff;
  align-items: center;
  padding: 0px;
  width: 228px;
  height: 56px;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.32px;
}

.bottomButton,
.topButton {
  display: flex;
  justify-content: space-around;
}

.bottomButtonInactive {
  display: grid;
  grid-template-columns: 55px auto;
  color: #ff3b30;
  justify-items: start;
  padding-left: 15px;
}

.bottomButton svg,
.topButton svg {
  fill: #596987;
}

.topButton:hover {
  background-color: #f2f8f8;
  color: #0a8081;
}

.topButton:hover svg {
  fill: #0a8081;
}

.bottomButton:hover,
.bottomButtonInactive:hover {
  background-color: #ffe0e0;
}

.topButton {
  color: #596987;
}

.bottomButton {
  color: #ff3b30;
  padding: 0 7px;
}
