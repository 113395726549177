.inputContainer {
  display: flex;
  flex-direction: column;
  padding: 14px;
  gap: 4px;
  isolation: isolate;
  /* width: 467px; */
  height: 69px;
  background: #f2f8f8;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  box-sizing: border-box;
  border: 1px solid #f2f8f8;
  cursor: pointer;
}

.inputField {
  background: #f2f8f8;
  border: none;
  outline: none;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #152837;
  cursor: pointer;
}

.inputContainer.active {
  background-color: #ffffff;
  border: 1px solid #0a8081;
}

.inputContainer.active .inputField {
  background-color: #ffffff;
  color: #152837;
}

.inputContainer.active,
.inputContainer.active .inputField,
.inputContainer.active .inputLabel {
  cursor: default;
}

.inputLabel {
  font-family: Ubuntu !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #596987;
  cursor: pointer;
}
