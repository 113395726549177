.actionsMenu {
  position: absolute;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 12px;
  width: 200px;
  height: 144px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.actionsMenu button {
  border: none;
  background-color: #ffffff;
  font-family: Ubuntu;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.3px;
  text-align: left;
  cursor: pointer;
  width: 168px;
  height: 56px;
  display: grid;
  grid-template-columns: 45px auto;
  align-items: center;
  border-radius: 8px;
  padding: 0 20px;
}

.changeButton {
  color: #596987;
  svg {
    fill: #596987;
  }
  &:hover {
    background-color: #f2f8f8;
    color: #0a8081;
    svg {
      fill: #0a8081;
    }
  }
}

.deleteButton {
  color: #ff3b30;
  svg {
    margin-left: 5px;
  }
  &:hover {
    background-color: #ffe0e0;
  }
}
