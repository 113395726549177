.container {
  display: flex;
  gap: 12px;
  padding: 0 24px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dayContainer {
  width: 120px;
  height: 90px;
  box-sizing: border-box;
  background-color: #f2f8f8;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dayHeader {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  span {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    color: #152837;
  }
}

.dayHeader h6 {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #152837;
}

.timeDisplay {
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  gap: 8px;
  width: 96px;
  height: 26px;
  box-sizing: border-box;
  background-color: #c7f0dc;
  border-radius: 4px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #0a8081;
  cursor: pointer;
}

.timeDisplay:hover {
  background-color: #0a8081;
  color: #ffffff;
}

.addButton {
  background: #f2f8f8;
  color: #0a8081;
  border: 1px solid #c7f0dc;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.addButton:hover {
  background-color: #0a8081;
  color: #ffffff;
  border-color: #ffffff;
}
