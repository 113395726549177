.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1001;
  max-width: 500px;
  width: 100%;
  height: 621px;
}

.titlePreference {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.preferenceContent {
  margin-top: 20px;
}

.preferenceForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.saveButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 23px 14px 28px;
  gap: 12px;
  margin: 0 auto;
  width: 117px;
  height: 52px;
  border-radius: 8px;
  border: none;
  background-color: #0a8081;
  color: #ffffff;
  margin: 24px 24px 24px 0;
  cursor: pointer;
  font-family: Ubuntu;
}

.saveButton:disabled {
  background-color: #f2f8f8;
  columns: #c6e1fd;
}

.backButton {
  padding: 24px 0;
  display: flex;
  justify-content: flex-start;
}

.returnButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 48px;
  height: 48px;
  background: #f2f8f8;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  position: absolute;
}

.returnButton svg {
  fill: #0a8081;
}

.returnButton:hover {
  background-color: #0a8081;
}

.returnButton:hover svg {
  fill: #ffffff;
}

.returnButton:active {
  background-color: #3b999a;
}

.returnButton:active svg {
  fill: #ffffff;
}

.wrapChangesForm {
  padding: 0 24px;
  position: relative;
}
