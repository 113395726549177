.backButton {
  position: absolute;
  top: 24px;
  left: 52%;
  background: none;
  border: none;
  cursor: pointer;
  width: 52px;
  height: 52px;
  padding: 14px;
  gap: 12px;
  border-radius: 8px;
  background-color: #f2f8f8;
  color: #0a8081;
  &:hover {
    background-color: #0a8081;
  }
  &:focus {
    background-color: #3b999a;
  }
}
.backButton:hover .icon path {
  fill: white;
}
