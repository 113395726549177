.container {
  position: relative;
}

.label {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
  color: #596987;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #596987;
}

.dateInput {
  box-sizing: border-box;
  width: 200px;
  height: 70px;
  border: 1px solid #f2f8f8;
  border-radius: 4px;
  text-align: left;
  padding: 32px 14px 14px 14px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #152837;
  background-color: #f2f8f8;
}

.dateInput.active {
  border-color: #0a8081;
  background-color: #ffffff;
}

.dateInput:focus {
  outline: none;
}
