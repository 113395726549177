.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1001;
}

.tittleModal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
  position: relative;
  background-color: #f2f8f8;
  height: 54px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.tittleModal h3 {
  margin: 0;
  font-family: 'Ubuntu';
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.tittleModal button {
  position: absolute;
  right: 0;
  top: 10px;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  background-color: #f2f8f8;
}

.tittleModal button svg {
  fill: #152837;
}

.tittleModal button:hover svg {
  fill: #0a8081;
}

.modalForm {
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
}

.contentInputs {
  display: flex;
  gap: 12px;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
}

.modalActions button {
  width: 296px;
  height: 52px;
  border-radius: 8px;
  border: none;
  ont-family: 'Ubuntu';
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  cursor: pointer;
  background-color: #0a8081;
  color: #ffffff;
}

.modalActions button:hover {
  background-color: #3b999a;
}

.modalActions button:disabled {
  background-color: #f2f8f8;
  color: #c6e1fd;
  cursor: default;
}
