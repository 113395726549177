.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  width: 140px;
  height: 52px;
  background: #f2f8f8;
  border-radius: 6px;
  color: #0a8081;
  border: none;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  cursor: pointer;
}

.button:hover {
  background-color: #0a8081;
  color: #ffffff;
}

.button:active {
  background-color: #3b999a;
}

.active {
  background-color: #0a8081;
  color: #ffffff;
}
