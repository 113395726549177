.wrapInformation,
.fewAdmins {
  margin-top: 24px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.fewAdmins {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.adminTitle {
  padding: 12px 16px;
  height: 46px;
  display: grid;
  grid-template-columns: 46px 1fr 1fr 64px;
  align-items: center;
  border-bottom: 1px solid #b8c0cd;
  box-sizing: border-box;
  h6 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #596987;
  }
}

.initialsWrap {
  display: flex;
  align-items: center;
}

.sortButton {
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 3px;
  svg {
    fill: #596987;
  }
}
.sortButton:hover {
  svg {
    fill: #0a8081;
  }
}

.noResults {
  p {
    padding: 50px 0;
    font-family: 'Ubuntu';
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.32px;
    text-align: center;
    color: #596987;
  }
}
