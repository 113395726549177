.auth_container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.auth_content {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 328px;
  margin: 0 auto;
}
