.parentContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.wrapPlan {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.displayPlan {
  background-color: #ffffff;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
}

.borderBottomRadius {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.plansTitles {
  display: grid;
  grid-template-columns: 46px 1fr 1fr;
  padding: 0 16px;
  border-bottom: 1px solid #e2ecf4;
}

.scrollPlan {
  flex-grow: 1;
  max-height: 69.5vh;
  position: relative;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollPlan::-webkit-scrollbar {
  display: none;
}

.planItem {
  display: grid;
  grid-template-columns: 43px 1.06fr 1fr 48px;
  align-items: center;
  margin: 0 16px;
  border-bottom: 1px solid #e2ecf4;
  height: 76px;
  box-sizing: border-box;
}

.planItem:last-child {
  border-bottom: none;
}

.plansTitles h5 {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #596987;
}

.sortName {
  display: flex;
}

.sortButton {
  border: none;
  background-color: #ffffff;
  cursor: pointer;
}

.sortButton svg {
  fill: #596987;
  margin-top: 4px;
}

.sortButton:hover svg {
  fill: #0a8081;
}

.infoWrap {
  display: flex;
  align-items: center;
}

.avatarWrap {
  width: 48px;
  height: 48px;
  margin-right: 14px;
  border-radius: 1000px;
  background-color: #e2ecf4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarWrap img {
  width: 48px;
  height: 48px;
  border-radius: 1000px;
}

.menuButton {
  width: 48px;
  height: 48px;
  background-color: #ffffff;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuButton:hover {
  background-color: #0a8081;
  border-radius: 8px;
}

.menuButton:hover svg {
  fill: #ffffff;
}

.menuButton svg {
  fill: #596987;
}

.planItem h5 {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.32px;
  margin: 0px;
}

.planStyle {
  display: inline-flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 4px;
  text-align: center;
  white-space: nowrap;
  height: 28px;
  box-sizing: border-box;
}

.fop {
  background-color: #ffeae5;
  color: #ff710a;
}

.subscription {
  background-color: #c6e1fd;
  color: #007aff;
}

.network {
  background-color: #f8fafc;
  color: #596987;
}

.legalEntity {
  background-color: #c7f0dc;
  color: #34c759;
}

.eventTranslation {
  background-color: #f9f4d9;
  color: #ff9f0a;
}

.noResultsMessage,
.noPlansMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #596987;
  padding: 45px 0;
}
