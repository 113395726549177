.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 8px;
  max-width: 90%;
  box-sizing: border-box;
}

.datePickerContainer {
  margin-bottom: 20px;
}

.monthPicker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.customSelect {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px 12px 24px;
  gap: 12px;
  width: 173px;
  height: 48px;
  background: #f2f8f8;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.customSelect.open {
  background: #ffffff;
  border: 1px solid #0a8081;
}

.dropdownMenu {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 12px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  padding: 5px 0;
  box-shadow:
    37px 86px 38px rgba(194, 194, 194, 0.03),
    21px 49px 32px rgba(194, 194, 194, 0.1),
    9px 22px 23px rgba(194, 194, 194, 0.17),
    2px 5px 13px rgba(194, 194, 194, 0.2);
}

.dropdownItem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  margin: 0 16px;
  gap: 12px;
  height: 56px;
  box-sizing: border-box;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.dropdownItem:hover {
  background-color: #f2f8f8;
  border-radius: 8px;
}

.dropdownMenu::-webkit-scrollbar {
  width: 8px;
}

.dropdownMenu::-webkit-scrollbar-thumb {
  background-color: #0a8081;
  border-radius: 8px;
}

.dropdownMenu::-webkit-scrollbar-thumb:hover {
  background-color: #3b999a;
}

.dropdownMenu::-webkit-scrollbar-track {
  background-color: #f2f8f8;
  border-radius: 8px;
}

.dropdownMenu {
  scrollbar-width: thin;
  scrollbar-color: #0a8081 #f2f8f8;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 16px 16px 16px;
}

.selectButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 23px 14px 28px;
  gap: 12px;
  width: 198.5px;
  height: 52px;
  background: #0a8081;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.selectButton:hover {
  background-color: #3b999a;
}

.selectButton:disabled {
  background-color: #f2f8f8;
  color: #c6e1fd;
  cursor: default;
}

.daysWeek {
  display: grid;
  padding: 0 23px;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  background-color: #f2f8f8;
  height: 56px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.title h6 {
  flex: 1;
  text-align: center;
  margin: 0;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #152837;
  cursor: default;
}

.title .closeButton {
  position: absolute;
  right: 16px;
}

.closeButton {
  background-color: #f2f8f8;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.closeButton svg {
  width: 18px;
  height: 18px;
  fill: #596987;
}

.closeButton:hover svg {
  fill: #0a8081;
}

.buttonRegulator {
  width: 48px;
  height: 48px;
  background-color: #f2f8f8;
  color: #263738;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  font-size: 20px;
}

.buttonRegulator:hover {
  background-color: #0a8081;
  color: #ffffff;
}

.arrow {
  width: 8px;
  height: 8px;
  border: solid #596987;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(-135deg);
}

.arrow {
  transform: rotate(45deg);
}

.dropdownWrap {
  display: flex;
  padding: 0 16px 16px 16px;
  gap: 10px;
}

.wrapButton {
  display: flex;
  padding: 0 16px 16px 16px;
  gap: 8px;
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 23px 14px 28px;
    gap: 12px;
    width: 198.5px;
    height: 52px;
    background: #0a8081;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-family: Ubuntu;
    &:disabled {
      background-color: #f2f8f8;
      color: #c6e1fd;
      cursor: default;
      &:hover {
        background-color: #f2f8f8;
      }
    }
    &:hover {
      background-color: #3b999a;
    }
  }
}
