.searchInput {
  min-width: 380px;
  height: 50px;
  box-sizing: border-box;
  background-color: #f2f8f8;
  border: 1px solid #f2f8f8;
  border-radius: 8px;
  padding-left: 50px;
  padding-top: 5px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  &::placeholder {
    color: #b8c0cd;
  }
  &:hover {
    border: 1px solid #c7f0dc;
  }
  &:focus {
    outline: none;
    border: 1px solid #0a8081;
    background-color: #ffffff;
  }
}

.wrap_input {
  position: relative;
  svg {
    position: absolute;
    left: 17px;
    top: 14px;
  }
}
.crossIcon {
  position: absolute;
  top: 2px;
  right: 50px;
  svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
    fill: #596987;
    &:hover {
      fill: #0a8081;
    }
  }
}
