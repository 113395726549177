.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.modalContent {
  background: white;
  box-sizing: border-box;
  padding: 16px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: auto;
  width: 200px;
  height: 144px;
  box-shadow:
    37px 86px 38px rgba(194, 194, 194, 0.03),
    21px 49px 32px rgba(194, 194, 194, 0.1),
    9px 22px 23px rgba(194, 194, 194, 0.17),
    2px 5px 13px rgba(194, 194, 194, 0.2);
  border-radius: 12px;
  button {
    display: grid;
    justify-items: start;
    align-items: center;
    grid-template-columns: 40px auto;
    width: 168px;
    height: 56px;
    border-radius: 8px;
    cursor: pointer;
    background-color: #ffffff;
    border: none;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.32px;
  }
}
.detailButton,
.inviteButton {
  color: #596987;
  padding-left: 20px;
  svg {
    fill: #596987;
  }
  &:hover {
    background-color: #f2f8f8;
    color: #0a8081;
    svg {
      fill: #0a8081;
    }
  }
}
.delateButton {
  color: #ff3b30;
  padding-left: 22px;
  svg {
    fill: #ff3b30;
  }
  &:hover {
    background-color: #ffe0e0;
  }
}

.borderBottom {
  border-bottom: 1px solid #cbddeb;
  width: 218px;
  margin-left: 10px;
}
