.otpContainer {
  width: 52px;
  height: 52px;
}

.wrapOtpInput {
  display: flex;
  flex-direction: row;
  gap: 48px;
}

.otpSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.resendLink {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #0a8081;
  cursor: pointer;
  text-decoration: none;
}

.text p {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  color: #596987;
  margin: 0;
}

.text {
  display: flex;
  justify-content: space-between;
}

.titelText h6 {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  color: #152837;
  padding-bottom: 8px;
}

.titelText p {
  margin: 0;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #596987;
}

.wrapNewEmail {
  display: flex;
  flex-direction: column;
  padding-top: 48px;
}

.wrapOtpInput input {
  font-size: 22px;
}

.wrapOtpInput div {
  padding: 0 0 0 15px;
  justify-content: center;
}
