.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 8px;
  max-width: 906px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 12px;
  position: relative;
}

.headerList {
  display: grid;
  grid-template-columns: 46px 1fr 1fr 1fr 48px;
  padding: 15px 24px;
  border-bottom: 1px solid #b8c0cd;
}

.headerList p {
  margin: 0;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #596987;
}

.infoChange {
  display: flex;
  align-items: center;
  width: 40px;
}

.infoChange svg {
  cursor: pointer;
  fill: #596987;
}

.infoChange svg:hover {
  fill: #0a8080;
}

.wrapSubmit {
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 23px 14px 28px;
  width: 117px;
  height: 52px;
  background: #0a8080;
  color: #ffffff;
  border-radius: 8px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.32px;
  border: none;
  cursor: pointer;
  margin: 24px;
  &:hover {
    background-color: #3b999a;
  }
}

.toggleContainer {
  position: absolute;
  top: 90px;
  right: 024px;
}

.toggleContainer label {
  display: flex;
  flex-direction: row-reverse;
}
