.schedule {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  gap: 4px;
}

.day {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  box-sizing: border-box;
}

.day.active {
  background-color: #008080;
  width: 40px;
  height: 40px;
}

.day.inactive {
  padding: 6px 8px 6px 8px;
  border-radius: 4px;
  display: flex;
  gap: 4px;
  background-color: #f2f8f8;
  color: black;
}
