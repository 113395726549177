.modalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f8f8;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.modalHeader h5 {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #152837;
  margin: 16px 0;
}

.modalHeader button {
  position: absolute;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.closeHeader svg {
  fill: #596987;
}

.closeHeader:hover svg {
  fill: #0a8080;
}
