.customerContainer {
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 12px;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.translators_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.translators_content {
  margin-top: 24px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}
.translators_content_many {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.translators_content:active {
  cursor: grabbing;
}

.head_list {
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #596987;
  display: grid;
  grid-template-columns: 38px 1fr 0.5fr 1fr;
  height: 46px;
  border-bottom: 1px solid #e2ecf4;
  padding: 0 16px;
  align-items: center;
}

.head_list h5 {
  margin: 0;
}

.initials {
  display: flex;
  align-items: center;
}

.initials button {
  border: none;
  background-color: #ffffff;
}

.initials button svg {
  cursor: pointer;
  width: 14px;
  height: 16px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: 500;
  color: #596987;
}
.no_results {
  .content {
    font-size: 16px;
    font-weight: 400;
  }
}

.board {
  overflow: auto;
  max-height: 71.4vh;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

.board::-webkit-scrollbar {
  display: none;
}

.error {
  .content {
    font-size: 16px;
  }
}

.no_results {
  .content {
    font-size: 16px;
    padding: 45px 0;
  }
}
