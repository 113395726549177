.client {
  display: grid;
  grid-template-columns: 38px 64px 0.43fr 0.24fr 0.45fr auto;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f2f8f8;
  height: 76px;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  margin: 0 16px;
  position: relative;
}

.clientInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex-shrink: 0;
}

.avatarPlaceholder {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #0a8081; */
  background-color: #e2ecf4;
}

.clientName {
  font-weight: bold;
  color: #596987;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clientPhone {
  color: #596987;
}

.clientDays {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
}

.day {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f2f8f8;
  color: #596987;
}

.activeDay {
  background-color: #0a8081;
  color: white;
}

.clientActions {
  text-align: right;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding-left: 9px;
  border-radius: 8px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #596987;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.actionButton:hover {
  background-color: #0a8081;
  color: #ffffff;
}

.actionButton:active {
  background-color: #3b999a;
  color: #ffffff;
}

h6 {
  margin: 0;
}

/* Styles for the menu container */
.menuContainer {
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Styles for the modal */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  width: 600px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
