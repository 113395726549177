form {
  position: relative;
}

.wrap_link {
  margin-top: 8px;
  display: flex;
  justify-content: end;
}

.text_link {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  position: relative;
  color: #0a8081;
  margin-right: 10px;
  text-decoration: none;
}

.text_link a {
  cursor: pointer;
  width: auto;
}

.login_title {
  font-family: 'Ubuntu';
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding-bottom: 24px;
}

.login_container {
  text-align: center;
}

.login_form {
  gap: 8px;
  position: relative;
}

.login_form button {
  margin-top: 16px;
}

.form_group {
  position: relative;
  margin-top: 8px;
}

.error_message {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  left: 0;
}
