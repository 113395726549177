.modalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.saveButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 23px 14px 28px;
  gap: 12px;
  width: 117px;
  height: 52px;
  border-radius: 8px;
  border: none;
  background-color: #0a8081;
  color: #ffffff;
  cursor: pointer;
  margin: 0 24px 24px 0;
}

.saveButton:disabled {
  background: #f2f8f8;
  color: #c6e1fd;
}

.saveButton:hover {
  background-color: #3b999a;
}
