.daysOfWeek {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  gap: 12px;
  padding: 0 24px;
}

.dayHeader {
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: right;
  text-transform: uppercase;
  box-sizing: border-box;
}
