.historyItem {
  border-bottom: 1px solid #ddd;
  display: grid;
  grid-template-columns: 38px 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
  align-items: center;
  height: 75px;
  justify-content: space-between;
}

.lastHistoryItem {
  border-bottom: none;
}

.useraName {
  display: flex;
  flex-direction: column;
  gap: '16px';
  margin: 0;
  padding: auto;

  p {
    margin: 0;
    padding: 0;
    line-height: 22px;
  }
}

.userNameLabel {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 400;
  color: #596987;
}
