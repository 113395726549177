.wrapInput {
  position: relative;
  margin-bottom: 1rem;
}

.wrapInputError {
  position: relative;
  margin-bottom: 1rem;
  border-color: red;
}

.selectContainer,
.selectContainerActive {
  box-sizing: border-box;
  width: 332px;
  height: 70px;
  border-radius: 8px;
  padding-left: 14px;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.selectContainer:hover {
  border: 1px solid #c7f0dc;
}

.selectContainer {
  background-color: #f2f8f8;
  border: 1px solid #f2f8f8;
}

.selectContainerActive {
  outline: none;
  border: 1px solid #0a8081;
  background-color: #ffffff;
}

.label {
  position: absolute;
  top: 5px;
  left: 14px;
  pointer-events: none;
  font-family: 'Ubuntu';
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #596987;
}

.selectedValue {
  flex-grow: 1;
  padding-top: 15px;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000;
  margin-right: 14px;
  transition: transform 0.3s;
}

.arrowOpen {
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1000;
  height: 300px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.dropdownMenu::-webkit-scrollbar {
  width: 8px;
}

.dropdownMenu::-webkit-scrollbar-thumb {
  background-color: #0a8081;
  border-radius: 4px;
  height: 16px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.dropdownItem {
  margin: 16px 16px 0 16px;
  padding: 16px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  width: 275px;
  height: 56px;
  border-radius: 8px;
  color: #596987;
  font-family: Ubuntu;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.32px;
  text-align: left;
}

.dropdownItem:hover {
  background-color: #f2f8f8;
  color: #0a8081;
}

.error {
  color: red;
  margin-top: 0.25rem;
}
