.submit_button {
  width: 328px;
  height: 52px;
  border-radius: 6px;
  border: none;
  font-family: Ubuntu;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
}
.valid {
  background-color: #3b999a;
  color: #ffffff;
  &:hover {
    background-color: #0a8081;
  }
}
.invalid {
  color: #c6e1fd;
  background-color: #f2f8f8;
}
