.wrapInput {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.passwordField input {
  background: #f2f8f8;
  border: none;
  outline: none;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #152837;
  cursor: pointer;
}

.passwordField label {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #596987;
  cursor: pointer;
}

.passwordField {
  display: flex;
  flex-direction: column;
  padding: 14px;
  gap: 4px;
  isolation: isolate;
  height: 69px;
  background: #f2f8f8;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  box-sizing: border-box;
  border: 1px solid #f2f8f8;
  cursor: pointer;
  position: relative;
}

.togglePassword {
  position: absolute;
  border: none;
  top: 0;
  right: 0;
}
