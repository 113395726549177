.wrapInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  h6 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.32px;
    color: #152837;
  }
}
