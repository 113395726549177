.avatarLoader {
  width: 92px;
  height: 92px;
  background: #e2ecf4;
  border-radius: 1000px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarPreview {
  width: 92px;
  height: 92px;
  border-radius: 1000px;
}
