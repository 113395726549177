.head_list {
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #596987;
  display: grid;
  grid-template-columns: 38px 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
  height: 46px;
  border-bottom: 1px solid #e2ecf4;
  padding: 0 16px;
  align-items: center;
}

.initials {
  display: flex;
  align-items: center;
}

.initials button {
  border: none;
  background-color: #ffffff;
}

.initials button svg {
  cursor: pointer;
  width: 14px;
  height: 16px;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.notRotated {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: 500;
  color: #596987;
}

.no_results {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
  .content {
    font-size: 16px;
    font-weight: 400;
  }
}

.history_content {
  margin-top: 24px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.history_content_many {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.head_list h5 {
  margin: 0;
}

.historyList {
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 16px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.32px;
  text-align: left;
  max-height: 69.5vh;
}
