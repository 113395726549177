.timePickerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timePickerContent {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  isolation: isolate;
  width: 254px;
  height: 169px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.timePickerBody {
  display: flex;
  gap: 12px;
}

.timeInputContainer {
  position: relative;
}

.timeInputContainer label {
  position: absolute;
  z-index: 1;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #596987;
  top: 14px;
  left: 14px;
}

.customTimePicker {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 14px 14px 14px;
  gap: 12px;
  isolation: isolate;
  width: 105px;
  height: 69px;
  background: #f2f8f8;
  border-radius: 6px;
  box-sizing: border-box;
}

.customTimePicker.open {
  background-color: #ffffff;
  border: 1px solid #0a8081;
}

.selectedTime {
  width: 38px;
  height: 23px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #152837;
}

.timeOptions {
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  isolation: isolate;
  width: 105px;
  height: 223px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow:
    37px 86px 38px rgba(194, 194, 194, 0.03),
    21px 49px 32px rgba(194, 194, 194, 0.1),
    9px 22px 23px rgba(194, 194, 194, 0.17),
    2px 5px 13px rgba(194, 194, 194, 0.2);
  border-radius: 12px;
}

.timeOption {
  cursor: pointer;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.32px;
  color: #596987;
  width: 64px;
  height: 56px;
  margin: 0 12px;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}

.timeOption:hover {
  background: #f2f8f8;
}

.saveButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 23px 14px 28px;
  gap: 12px;
  width: 222px;
  height: 52px;
  background: #0a8081;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.saveButton:hover {
  background-color: #3b999a;
}

.timeOptions::-webkit-scrollbar {
  width: 6px;
}

.timeOptions::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 12px;
}

.timeOptions::-webkit-scrollbar-thumb {
  background-color: #0a8081;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.timeOptions::-webkit-scrollbar-thumb:hover {
  background-color: #3b999a;
}
