.inputContainer {
  display: flex;
  flex-direction: column;
}

.customSelectContainer {
  position: relative;
  cursor: pointer;
  width: 327px;
}

.customSelectContainer label {
  position: absolute;
  padding-left: 14px;
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 400;
  line-height: 22.5px;
  top: 4px;
}

.selectedOption {
  height: 69px;
  background-color: #f2f8f8;
  border: 1px solid #f2f8f8;
  border-radius: 8px;
  padding-left: 14px;
  padding-top: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 29px 0 0 14px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
}

.selectedOption label {
  position: absolute;
  top: 3px;
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 400;
  line-height: 22.5px;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #c7f0dc;
  border-radius: 8px;
  background-color: #f2f8f8;
  z-index: 1;
  max-height: 150px;
  overflow-y: auto;
}

.selectedOption:hover {
  border: 1px solid #c7f0dc;
}

.selectedOption:focus {
  outline: none;
  border: 1px solid #0a8081;
  background-color: #ffffff;
}

.option {
  padding: 8px 0 8px 14px;
  cursor: pointer;
}

.option:hover,
.option.selected {
  background-color: #f2f8f8;
}

.option:hover {
  color: #0a8081;
}

.open .selectedOption {
  background-color: #ffffff;
  border-color: #0a8081;
}
