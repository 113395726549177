.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .modalContent {
    width: 328px;
    padding: 24px 0px 24px 0px;
    border-radius: 12px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.modalContent img {
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
}

.modalContent h5 {
  font-family: Ubuntu;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
  margin: 0 0 4px 0;
}

.modalContent p {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #596987;
  margin: 0 0 24px 0;
}

.modalButton {
  width: 280px;
  height: 52px;
  padding: 14px 23px 14px 28px;
  gap: 12px;
  border-radius: 6px;
  border: none;
  background-color: #0a8081;
  color: #ffffff;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
  cursor: pointer;
}
