.customers {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.management_content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 1;
}

.management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f8f8;
  min-height: 90px;
  padding: 0 24px;
}

.header_title {
  font-family: Ubuntu;
  font-size: 26px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin: 0;
}

.header_button {
  border: none;
  background-color: white;
  cursor: pointer;
  padding: 0 20px;
}

.management_active_component {
  display: flex;
  flex-grow: 1;
  background-color: #e2ecf4;
  padding: 24px 24px 0 24px;
}
