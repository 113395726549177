.panel_wrap {
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container_button {
  display: flex;
  gap: 8px;
}

.container_button button {
  box-sizing: border-box;
  padding: 14px 30px;
  gap: 12px;
  border-radius: 6px;
  border: none;
  background-color: #f2f8f8;
  color: #0a8081;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
  cursor: pointer;
}

.container_button button:active {
  background-color: #3b999a;
}

.container_button button:active svg {
  padding-right: 30px;
  fill: #0a8081;
}

.container_button button:hover {
  background-color: #0a8081;
  color: #ffffff;
}

.container_button button:hover svg {
  fill: #ffffff;
}

.wrapState {
  display: flex;
  margin-right: 12px;
}

.defaultStaffs {
  display: flex;
}

.buttonContent {
  display: flex;
  align-items: center;
}

.buttonContent svg {
  fill: #0a8080;
  margin-right: 17px;
}

.addActiveButton:hover button svg {
  fill: #ffffff;
}
