.head_list {
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #596987;
  display: grid;
  grid-template-columns: 38px 0.27fr 0.225fr 0.18fr 0.18fr auto;
  height: 46px;
  border-bottom: 1px solid #e2ecf4;
  padding: 0 16px;
  align-items: center;
}

.headCustomer {
  grid-template-columns: 38px 0.27fr 0.225fr 0.18fr 0.18fr auto;
}

.headCompany {
  grid-template-columns: 38px 0.59fr 17ch 0.25fr 0.25fr 0.2fr 40px;
}

.initials,
.term {
  display: flex;
  align-items: center;
}

.initials button,
.term button {
  border: none;
  background-color: #ffffff;
  cursor: pointer;
}

.initials svg,
.term svg {
  width: 14px;
  height: 16px;
}

.initials h5,
.term h5 {
  margin: 0;
  padding-bottom: 4px;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
