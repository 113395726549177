.formOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.formContainer {
  background: white;
  border-radius: 12px;
  z-index: 1001;
  position: relative;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  background-color: #f2f8f8;
  height: 54px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  h2 {
    margin: 0;
    font-family: Ubuntu;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
  }
}

.closeButton {
  position: absolute;
  right: 0;
  top: 10px;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  background-color: #f2f8f8;
  svg {
    fill: #152837;
  }
  &:hover {
    svg {
      fill: #0a8081;
    }
  }
}

.formContainer form {
  display: flex;
  flex-direction: column;
}
.wrap_form {
  padding: 0 24px 24px 24px;
}

.personal_information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
}
.section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  align-items: center;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
  }
}
.title {
  font-family: Ubuntu;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.32px;
  text-align: left;
  h4 {
    margin: 0;
  }
  padding-bottom: 12px;
}

.buttons,
.buttons_disabled,
.scheduleButton {
  width: 180px;
  height: 52px;
  border-radius: 8px;
  border: none;
  padding: 14px, 23px, 14px, 28px;
  gap: 12px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.buttons {
  background-color: #0a8081;
  color: white;
  cursor: pointer;
}
.footer {
  display: flex;
  justify-content: space-between;
}
.scheduleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a8081;
  background-color: #f2f8f8;
  cursor: pointer;
  svg {
    fill: #0a8081;
  }
  &:hover {
    background-color: #0a8081;
    color: #ffffff;
    svg {
      fill: #ffffff;
    }
  }
  &:active {
    background-color: #3b999a;
    color: #ffffff;
    svg {
      fill: #ffffff;
    }
  }
}
.buttons_disabled {
  background-color: #f2f8f8;
  color: #c6e1fd;
}
