.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 430px;
  border-radius: 12px;
}

.modalContent {
  text-align: center;
  .title,
  .message {
    margin: 0;
    font-family: Ubuntu;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
  }
  .title {
    font-weight: 500;
  }
  .message {
    font-weight: 400;
    padding: 24px 0;
  }
  p {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.32px;
    color: #152837;
  }
}

.detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f2f8f8;
  margin: 0 24px;
  img {
    width: 48px;
    height: 48px;
    border-radius: 999px;
    margin-right: 14px;
  }
  p {
    margin: 0;
  }
}

.title {
  background-color: #f2f8f8;
  height: 54px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalActions {
  display: flex;
  justify-content: space-around;
  margin: 48px 24px 24px 24px;
  button {
    width: 186px;
    height: 52px;
    background-color: #0a8081;
    color: white;
    border: none;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: #3b999a;
    }
  }
}

.wrapInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  gap: 14px;
}

.nonAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: #e2ecf4;
  border-radius: 1000px;
  svg {
    fill: #0a8081;
  }
}
