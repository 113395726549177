.noEmployees {
  padding: 16px;
  text-align: center;
}

.employeeContainer {
  height: 400px;
  overflow: auto;
}

.employeeContainer::-webkit-scrollbar {
  display: none;
}

.employeeContainer {
  scrollbar-width: none;
}
