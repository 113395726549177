.datesGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  gap: 12px;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
}

.dateCell,
.emptyCell {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #f2f8f8;
  height: 89px;
  border-radius: 6px;
  box-sizing: border-box;
}
.numberDate {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.3px;
  text-align: left;
  padding-top: 12px;
  padding-right: 12px;
}

.addButton {
  width: 96px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #c7f0dc;
  cursor: pointer;
  background-color: #f2f8f8;
  color: #0a8081;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-right: 12px;
  margin-bottom: 14px;
  text-align: center;
  padding: 0;
}

.time {
  width: 96px;
  height: 26px;
  border-radius: 4px;
  background-color: #c7f0dc;
  color: #0a8081;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-right: 12px;
  margin-bottom: 14px;
  text-align: center;
  padding: 5px 0;
  box-sizing: border-box;
}
