.activeButton,
.navButton {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 10px;
  padding-left: 10px;
  width: 176px;
  height: 49px;
  border-radius: 8px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.3px;
  text-align: left;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
}

.navButton {
  color: #596987;
  &:hover {
    background-color: #f2f8f8;
  }
}
.activeButton {
  background-color: #f2f8f8;
  color: #0a8081;
}
