.itemContainer {
  position: relative;
  display: grid;
  grid-template-columns: 46px 0.9fr 0.9fr 0.75fr 48px 48px;
  margin: 0 24px;
  align-items: center;
  height: 76px;
  border-bottom: 1px solid #b8c0cd;
  z-index: 0;
  &:last-child {
    border: none;
  }
}

.itemContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: -24px;
  width: calc(100% + 48px);
  height: 100%;
  z-index: -1;
  .wrapInfo {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .wrapInfo img {
    width: 48px;
    height: 48px;
    background: url();
    border-radius: 1000px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  transition: background-color 0.3s;
}

.itemContainer:hover::before {
  background-color: #f2f8f8;
}

.itemContainer p {
  margin: 0;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  align-items: center;
  letter-spacing: -0.32px;
  color: #152837;
}

.wrapInfo {
  display: flex;
  align-items: center;
  gap: 14px;
}

.wrapInfo img {
  width: 48px;
  height: 48px;
  background: url();
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.deleteButton {
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  svg {
    fill: #596987;
  }
  &:hover {
    background-color: #0a8080;
    svg {
      fill: #ffffff;
    }
  }
}
