.saveButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex: 1;
  button {
    margin-right: 2%;
  }
}

.saveButton {
  width: 10%;
  height: 5vh;
  font-family: Ubuntu;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
  cursor: pointer;
  background-color: #0a8081;
  color: #ffffff;
  border-radius: 8px;
  border: none;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f8f8;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  padding: 1rem;
}

.title h4 {
  font-family: Ubuntu;
  font-size: 1rem;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  margin: 0;
  flex: 1;
}

.closeButton {
  border: none;
  background-color: #f2f8f8;
  cursor: pointer;
  padding: 0;
  height: 24px;
  position: absolute;
  right: 1rem;
  svg {
    fill: #596987;
    &:hover {
      fill: #0a8081;
    }
  }
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
}

@media (max-width: 768px) {
  .saveButton {
    width: 20%;
    height: 5vh;
  }

  .title h4 {
    font-size: 1.2rem;
  }

  .closeButton {
    right: 0.5rem;
  }

  .calendarContainer {
    gap: 5%;
  }
}
