.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.modalTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 16px 0;
  background-color: #f2f8f8;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  h6 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: #152837;
  }
}

.modalTitle h6 {
  flex-grow: 1;
  margin: 0;
}

.closeButton {
  position: absolute;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  svg {
    fill: #596987;
  }
  &:hover {
    svg {
      fill: #0a8081;
    }
  }
}

.confirmText {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #152837;
  margin: 24px;
}

.userInfo {
  display: flex;
  align-items: center;
  background-color: #f2f8f8;
  margin: 0 24px;
  p {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    color: #152837;
  }
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 10px 0;
  padding: 0 14px;
}

.buttonGroup {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.buttonGroup {
  display: flex;
  padding: 24px;
  gap: 10px;
}

.confirmButton,
.cancelButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 25px 14px 28px;
  gap: 12px;
  width: 190.5px;
  height: 52px;
  background: #0a8081;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  color: #fff;
}
.confirmButton:hover,
.cancelButton:hover {
  background-color: #3b999a;
}
