.tagSelector {
  position: relative;
}

.inputClose,
.inputOpen {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 14px;
  gap: 5px;
  isolation: isolate;
  width: 668px;
  height: 69px;
  border-radius: 6px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #152837;
  label {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #596987;
  }
}

.inputOpen {
  background-color: #ffffff;
  border: 1px solid #0a8081;
  label {
    color: #086969;
  }
}

.inputClose {
  background-color: #f2f8f8;
  border: 1px solid #f2f8f8;
}

.inputClose:hover {
  border: 1px solid #c7f0dc;
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;
  position: absolute;
  width: 669px;
  max-height: 492px;
  box-sizing: border-box;
  left: 557px;
  top: 320px;
  background-color: #ffffff;
  box-shadow:
    37px 86px 38px rgba(194, 194, 194, 0.03),
    21px 49px 32px rgba(194, 194, 194, 0.1),
    9px 22px 23px rgba(194, 194, 194, 0.17),
    2px 5px 13px rgba(194, 194, 194, 0.2);
  border-radius: 12px;
  z-index: 1;
  top: 78px;
  left: 0;
  padding: 16px;
}

.tagList {
  max-height: 392px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #0a8081 #f2f8f8;
}

.tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 637px;
  height: 56px;
  border-radius: 8px;
  padding: 15px 0 15px 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.tag:hover {
  background-color: #f2f8f8;
  color: #0a8081;
  .deleteButton {
    svg {
      fill: #0a8081;
    }
  }
}

.selected {
  color: #0a8081;
  .deleteButton {
    svg {
      fill: #0a8081;
    }
  }
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  svg {
    fill: #596987;
  }
}

.svgIcon {
  margin-left: auto;
  margin-right: 16px;
}

.addButton {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  background-color: #0a8081;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.addButton:hover {
  background-color: #086969;
}

.newTagInput {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.searchWrap {
  position: relative;
  width: 100%;
  padding-bottom: 16px;
  box-sizing: border-box;
}

.searchWrap .leadingIcon {
  fill: #596987;
  position: absolute;
  top: 17px;
  left: 17px;
  z-index: 1;
  transition: fill 0.3s;
}

.searchWrap .leadingIcon.focused {
  fill: #0a8081;
}

.searchInput,
.newTagInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  isolation: isolate;
  width: 100%;
  height: 52px;
  background-color: #f2f8f8;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #f2f8f8;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #b8c0cd;
  transition:
    border-color 0.3s,
    background-color 0.3s;
}

.searchInput {
  padding: 14px 14px 14px 52px;
}

.newTagInput {
  padding: 14px;
}

.searchInput:hover,
.newTagInput:hover {
  border-color: #c7f0dc;
}

.searchInput:focus,
.newTagInput:focus {
  background-color: #ffffff;
  border-color: #0a8081;
  outline: none;
}

.tagList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.tagList::-webkit-scrollbar-track {
  background: #f2f8f8;
  border-radius: 100px;
}

.tagList::-webkit-scrollbar-thumb {
  background-color: #0a8081;
  border-radius: 100px;
  border: 2px solid #f2f8f8;
}

.tagList::-webkit-scrollbar-thumb:hover {
  background-color: #086969;
}
