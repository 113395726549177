.formWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
}

.modalWrap {
  background: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  box-shadow:
    37px 86px 38px rgba(194, 194, 194, 0.03),
    21px 49px 32px rgba(194, 194, 194, 0.1),
    9px 22px 23px rgba(194, 194, 194, 0.17),
    2px 5px 13px rgba(194, 194, 194, 0.2);
  border-radius: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.companyForm {
  padding: 24px;
  width: 720px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.submitButton,
.employeesButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 23px 14px 28px;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  border: none;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.submitButton {
  background: #0a8081;
  color: #ffffff;
  &:hover {
    background-color: #3b999a;
  }
}

.employeesButton {
  background: #f2f8f8;
  color: #0a8081;
  svg {
    fill: #0a8081;
    margin-right: 14px;
  }
  span {
    padding-top: 5px;
  }
  &:hover {
    background-color: #0a8081;
    color: #ffffff;
    svg {
      fill: #ffffff;
    }
  }
}

.submitButton:disabled {
  background: #f2f8f8;
  cursor: default;
}
