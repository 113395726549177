.input_container {
  display: flex;
  gap: 18.5px;
}
.otp_input {
  width: 51px;
  height: 51px;
  background-color: #f2f8f8;
  border: 1px solid #f2f8f8;
  border-radius: 8px;
  padding: 0;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: center;
  &::placeholder {
    color: #b8c0cd;
  }
  &:hover {
    border: 1px solid #c7f0dc;
  }
  &:focus {
    outline: none;
    border: 1px solid #0a8081;
    background-color: #ffffff;
  }
}
